
#app {

.label {
    font-family: inherit;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
    vertical-align: middle;
    text-align: center;
}

tfoot td {
    text-align: center;
}

.nav {
    .logo {
        padding: 5px 30px;
        background: #fef5e9;
        margin-bottom: 15px;
    }
    .logo-top {
        width: 100%;
    }
}

.navbar-top-links li a {
    padding: 15px 10px;
    min-height: 50px;
}

.navbar-header {
    margin-left: 25px;
    h2 {
        margin-top: 12px;
    }
}

.countries td input {
    width: 80px;
}

.label {
    font-size: 12px;
    font-weight: 400;
}

table .btn-group a {
    padding-top: 2px;
}
}